import React from "react"
import { Link } from "react-router-dom"
import { withNamespaces } from 'react-i18next'
import { ReactComponent as Text } from "./images/dynamic_charter.svg"
import { ReactComponent as TextMobile } from "./images/dynamic_charter_mob.svg"
import { ReactComponent as Arrow } from "./images/arrow.svg"
import { ReactComponent as HomeBg } from "./images/home_image.svg"

class Home extends React.Component {
  render() {
    const { t } = this.props

    return (
      <section className="section home">
        <div className="content">
          <h2>{t("subtitle")}</h2>
          <Text className="svg-text" />
          <TextMobile className="svg-text-mobile" />
          <p dangerouslySetInnerHTML={{ __html: t("text") }} />
          <Link to="/contact" className="button arrow-right">
            {t("common:contact_us")}
          </Link>
          <Arrow className="scroll" />
        </div>
        <HomeBg className="home-image" />
      </section>
    )
  }
}

export default withNamespaces(["home", "common"])(Home)