import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import LocaleRoot from "./LocaleRoot"
import i18n from './i18n'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Switch>
            <Route path="/en" component={LocaleRoot} />
            <Route component={LocaleRoot} />
          </Switch>
        </BrowserRouter>
      </I18nextProvider>
    )
  }
}

export default App;
