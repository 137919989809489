import React from 'react'
import { withRouter } from 'react-router-dom'
import TopMenu from "./TopMenu"
import ReactFullpage from '@fullpage/react-fullpage'
import { ReactComponent as Logo } from "./images/logo.svg"
import Grains from "./images/grains.png"
import { ReactComponent as Hamburger } from "./images/hamburger.svg"

import { debounce } from "lodash"

import Home from "./Home"
import Products from "./Products"
import Contacts from "./Contacts"
import config from "./navConfig"

const apiContext = React.createContext()
const { Provider, Consumer } = apiContext
export { Consumer }

class Root extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      moved: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.onLeave = this.onLeave.bind(this)
    this.onSlideLeave = this.onSlideLeave.bind(this)
    this.onResize = debounce(this.onResize.bind(this), 500)
  }

  componentWillMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentDidMount() {
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  componentDidUpdate() {
    const { initialized, moved } = this.state
    const {
      match: {
        params: {
          section
        }
      }
    } = this.props

    if (initialized && !moved) {
      this.fullpageApi.silentMoveTo(section)

      this.setState({ moved: true })
    }

    if (initialized && moved) {
      this.fullpageApi.moveTo(section)
    }
  }

  toggleMenu() {
    this.setState({ menuOpened: !this.state.menuOpened })
  }

  onResize() {
    const { fullpageApi } = this
    const width = window.innerWidth

    if (!fullpageApi) return

    if (width < 758) {
      fullpageApi.setResponsive(true)
    } else {
      fullpageApi.setResponsive(false)
    }
  }

  onLeave(_, { anchor }) {
    const {
      history,
      match: {
        url,
        params: {
          section
        }
      }
    } = this.props

    if (section === anchor) return

    let newUrl = url.replace(section, anchor)

    if (anchor !== "products") newUrl = newUrl.slice(0, newUrl.indexOf(anchor) + anchor.length)

    history.push(newUrl)
  }

  onSlideLeave(_, __, destination, ___) {
    const { history } = this.props

    history.replace(`/products/${destination.index + 1}`)
  }

  render() {
    const { menuOpened } = this.state

    return (
      <div>
        <Logo className="logo" />
        <img className="grains" src={Grains} alt="" />
        <Hamburger className="hamburger" onClick={this.toggleMenu}/>
        <TopMenu active={menuOpened} toggle={this.toggleMenu} />
        <ReactFullpage
          licenseKey="27FBB478-E30A46E1-A8485D19-34056784"
          anchors={config.map(({ key }) => key)}
          lockAnchors
          controlArrows={false}
          verticalCentered={false}
          afterRender={() => {
            this.setState({ initialized: true })
          }}
          onSlideLeave={this.onSlideLeave}
          onLeave={this.onLeave}
          render={({ fullpageApi }) => {
            this.fullpageApi = fullpageApi

            return (
              <Provider value={fullpageApi}>
                <ReactFullpage.Wrapper>
                  <Home />
                  <Products fullpageApi={fullpageApi} />
                  <Contacts />
                </ReactFullpage.Wrapper>
              </Provider>
            )}
          }
        />
      </div>
    )
  }
}

export default withRouter(Root)