export default [
  {
    key: "home"
  },
  {
    key: "products"
  },
  {
    key: "contact"
  }
]