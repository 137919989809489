import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import en from './locales/en.json'

export const langs = ['en']

i18n
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: {
      en
    }
  })

export default i18n