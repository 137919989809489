import React from "react"
import { withNamespaces } from 'react-i18next'
import { Formik, Field, getIn } from 'formik'
import * as  Yup from 'yup'
import { ReactComponent as Letters } from "./images/footer_letters.svg"

const emailRegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

const schema = Yup.object({
  email: Yup.string().matches(emailRegExp, 'E-mail is a required field').required('E-mail is a required field'),
  subject: Yup.string().required('Subject is a required field'),
  message: Yup.string().required('Message is a required field'),
})

class Contacts extends React.Component {
  async handleSubmit(values, { setSubmitting, setStatus }) {
    try {
      const formData = new FormData()
      formData.append('auth_key', 'rsbiyb3vzm1man0s9af4aswb6rywpb6l')

      for (let key in values) {
        formData.append(key, values[key])
      }

      await fetch('https://bm-materials.com/api/dynamic-charter/message/send', {
        method: 'POST',
        contentType: 'multipart/form-data',
        body: formData,
      })

      setSubmitting(false)
      setStatus('submitted')
    } catch (error) {
      alert(error)
    }
  }

  render() {
    const { t } = this.props

    return (
      <section className="section contacts">
        <div className="texts">
          <h6 className="subtitle">{t("get_in_touch")}</h6>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: t("contact_us") }} />
          <p className="address">
            {t("addresses", { returnObjects: true }).map((text, index) => (
              <React.Fragment key={index}>
                {text}
                {index !== t("phone").length - 1 && <br />}
              </React.Fragment>
            ))}
          </p>
        </div>
        <div className="form">
          <Formik
            initialValues={{
              email: '',
              subject: '',
              message: '',
            }}
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={this.handleSubmit}
          >
            {({ submitForm, isSubmitting, status }) => (
              <form onSubmit={e => e.preventDefault()}>
                {status === 'submitted' ? (
                  <span className='thank-you'>{t('thank_you')}</span>
                ) : (
                  <React.Fragment>
                    <h2 className="title">{t("send_a_message_for_us")}</h2>
                    <div className="form-row">
                      <Field
                        name='email'
                        render={({ field, form }) => {
                          const error = getIn(form.errors, field.name)

                          return (
                            <div className='field'>
                              <input placeholder={t("your_email")} {...field} />
                              {error && <span className='error'>{error}</span>}
                            </div>
                          )
                        }}
                      />
                      <Field
                        name='subject'
                        render={({ field, form }) => {
                          const error = getIn(form.errors, field.name)

                          return (
                            <div className='field'>
                              <input placeholder={t("subject")} {...field} />
                              {error && <span className='error'>{error}</span>}
                            </div>
                          )
                        }}
                      />
                    </div>
                    <Field
                      name='message'
                      render={({ field, form }) => {
                        const error = getIn(form.errors, field.name)

                        return (
                          <div className='field'>
                            <textarea placeholder={t("your_message")} {...field} />
                            {error && <span className='error'>{error}</span>}
                          </div>
                        )
                      }}
                    />
                    <button onClick={submitForm} className="form-button" disabled={isSubmitting}>{t("send")}</button>
                  </React.Fragment>
                )}
              </form>
            )}
          </Formik>
        </div>
        <footer>{t("common:copyright")}</footer>
        <Letters className="letters" />
      </section>
    )
  }
}

export default withNamespaces("contact", "common")(Contacts)
