import React from 'react'
import i18n from './i18n'
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Root from "./Root"

import config from "./navConfig"

class LocaleRoot extends React.Component {
  componentWillMount() {
    this.changeLocaleByUrl()
  }

  componentDidUpdate() {
    this.changeLocaleByUrl()
  }

  changeLocaleByUrl() {
    const locale = this.props.match.path.slice(1) || "en"

    i18n.changeLanguage(locale)
  }

  render() {
    const { match: { path, url } } = this.props
    const hasLocaleInUrl = url.indexOf("/en") !== -1
    const sections = config.map(({ key }) => key)

    return (
      <BrowserRouter>
        <Switch>
          <Route path={`${path.length > 1 ? path : ""}/:section/:product`} render={({ match: { params: { section } } }) => {
            if (sections.indexOf(section) === -1) return <Redirect to={`/${sections[0]}`} />

            return <Root />
          }} />
          <Route path={`${path.length > 1 ? path : ""}/:section`} render={({ match: { params: { section } } }) => {
            if (sections.indexOf(section) === -1) return <Redirect to={`/${sections[0]}`} />

            return <Root />
          }} />
          <Redirect to={`${hasLocaleInUrl ? "/en" : ""}/${sections[0]}`} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default withRouter(LocaleRoot)