import React from "react"
import { NavLink } from "react-router-dom"
import { withNamespaces } from 'react-i18next'
import { ReactComponent as House } from "./images/home.svg"
import { ReactComponent as Products } from "./images/products.svg"
import { ReactComponent as Contacts } from "./images/contacts.svg"

import config from "./navConfig"

const TopMenu = ({ t, active, toggle }) => (
  <nav className={`top-menu${active ? " active" : ""}`}>
    <ul>
      {config.map(({ key }) => (
          <li key={key}>
            <NavLink to={`/${key}`} onClick={toggle}>
              <div className="icon-wrapper">
                {key === "home" && <House />}
                {key === "products" && <Products />}
                {key === "contact" && <Contacts />}
              </div>
              {t(`${key}:menu_name`)}
            </NavLink>
          </li>
        )
      )}
    </ul>
  </nav>
)

export default withNamespaces()(TopMenu)
