import React from "react"
import { Link, withRouter, Redirect } from "react-router-dom"
import { throttle } from "lodash"
import { withNamespaces } from 'react-i18next'
import Circles from "./images/circles.png"
import { ReactComponent as Arrow } from "./images/arrow.svg"

class Products extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      moved: props.match.url.indexOf("/products") === -1 ? true : false
    }

    this.goPrev = throttle(this.goPrev.bind(this), 1000)
    this.goNext = throttle(this.goNext.bind(this), 1000)
  }

  componentDidMount() {
    const { history, match: { url, params: { product = 1 } } } = this.props

    this.setState({ initialized: true })

    if (url.indexOf("/products") === -1) return

    if (isNaN(Number(product))) {
      history.replace(`/products/1`)
    } else {
      history.replace(`/products/${Number(product)}`)
    }

  }

  componentDidUpdate(prevProps) {
    const { initialized, moved } = this.state
    const {
      fullpageApi,
      history,
      match: {
        url,
        params: {
          product = 1
        }
      }
    } = this.props

    if (prevProps.match.url !== url && url === "/products") history.push(`/products/${Number(product)}`)

    if (url.indexOf("/products") === -1) return

    if (initialized && !moved) {
      if (product) {
        fullpageApi.silentMoveTo("products", Number(product) - 1)
      } else {
        history.replace("/products/1")
      }

      this.setState({ moved: true })
    }

    if (initialized && moved) {
      fullpageApi.moveTo("products", Number(product) - 1)
    }
  }

  goPrev() {
    const { history, t, match: { params: { product }} } = this.props

    const count = t(`products`, { returnObjects: true }).length

    if (Number(product) !== 1) {
      history.replace(`/products/${Number(product) - 1}`)
    } else {
      history.replace(`/products/${count}`)
    }
  }

  goNext() {
    const { history, t, match: { params: { product }} } = this.props

    const count = t(`products`, { returnObjects: true }).length

    if (Number(product) !== count) {
      history.replace(`/products/${Number(product) + 1}`)
    } else {
      history.replace(`/products/1`)
    }
  }

  render() {
    const { t, fullpageApi, match: { params: { product = 1 }} } = this.props

    if (isNaN(Number(product))) return <Redirect to="/products/1" />

    const products = t(`products`, { returnObjects: true })
    const count = products.length

    const prevIndex = Number(product) === 1 ? count - 1 : product - 2
    const nextIndex = Number(product) === count ? 0 : product

    const prevText = t(`products`, { returnObjects: true })[prevIndex].title
    const nextText = t(`products`, { returnObjects: true })[nextIndex].title

    return (
      <section className="section products">
        <button className="prev" onClick={this.goPrev}>
          <span className="text">{prevText}</span>
          <span className="arrow">
            <Arrow />
          </span>
        </button>
        {products.map((item, index) => (
          <div className="slide product" key={index}>
            <h2 className="title-mobile">{item.title}</h2>
            <div className="images">
              <img src={Circles} alt="" className="circles" />
              <img src={require(`./images/${item.image}`)} alt="" className="photo" />
            </div>
            <div className="dots" key={index}>
              {products.map((_, index) => (
                <div key={index} className={`dot${index === product - 1 ? " active" : ""}`} onClick={() => fullpageApi.moveTo("products", index)}></div>
              ))}
            </div>
            <div className="pages">{`${product}/${count}`}</div>
            <div className="info">
              <h2 className="title">{item.title}</h2>
              <p className="text" dangerouslySetInnerHTML={{ __html: item.text }} />
              <h4 className='table-title'>{t('chemical_composition')}</h4>
              <table>
                <thead>
                  <tr>
                    <th>{t('name')}</th>
                    <th>{t('typical')}</th>
                    <th>{t('minimum')}</th>
                    <th>{t('maximum')}</th>
                  </tr>
                </thead>
                <tbody>
                  {item.contents.map((item, index) => (
                    <tr key={index}>
                      {item.map((element, index) => index === 0 ? (
                        <td key={index} dangerouslySetInnerHTML={{ __html: element }} />
                      ) : <td key={index}>{`${element}%`}</td>)}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Link to="/contact" className="button">{t("common:ask_a_question")}</Link>
            </div>
          </div>
        ))}
        <button className="next" onClick={this.goNext}>
          <span className="text">{nextText}</span>
          <span className="arrow">
            <Arrow />
          </span>
        </button>
      </section>
    )
  }
}

export default withRouter(withNamespaces("products", "common")(Products))
